<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    :left="left"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :class="{ 'date-select-text-field': filterStyle }"
        :value="formatedDate"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        outlined
        :hide-details="hideDetails"
        :disabled="disabled"
        :dense="dense"
        :label="label"
        :error="error"
        :error-messages="errorMessages"
        @click:append="toggleDateSelection"
        v-on="on"
      ></v-text-field>
    </template>
    <div class="d-flex flex-row">
      <div v-if="type === 'range'" class="d-flex flex-column pa-4" style="background-color: #334A58">
        <span class="mb-2" style="color: white; font-weight: bold">{{ label }}</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': todayDate }" @click="setToday()">Hoje</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': thisWeek }" @click="setThisWeek()">Esta semana</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': thisMonth }" @click="setThisMonth()">Este mês</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': lastMonth }" @click="setLastMonth()">Mês passado</span>
        <span class="pre-selected-date-btn" :class="{ 'active-pre-selected-date': !todayDate && !thisWeek && !thisMonth && !lastMonth }" @click="clearDates()"
          >Personalizado</span
        >
      </div>
      <v-date-picker
        v-if="type === 'range'"
        v-model="dates"
        background-color="white"
        scrollable
        no-title
        locale="pt-br"
        range
        :disabled="disabled"
        :min="minRangeDate"
        :max="max"
      >
        <template v-if="!hideButtons" #default>
          <v-spacer />
          <mf-button label="Limpar" outlined color="error" text size="sm" @click="clearAndClose" />
          <mf-button color="primary" label="Aplicar" size="sm" @click="saveAndClose" />
        </template>
      </v-date-picker>
      <v-date-picker
        v-else
        v-model="singleDate"
        background-color="white"
        :type="type"
        scrollable
        no-title
        locale="pt-br"
        :disabled="disabled"
        :min="min"
        :max="max"
        @input="saveAndClose"
      >
        <template v-if="!hideButtons" #default>
          <v-spacer />
          <mf-button label="Limpar" outlined color="error" text size="sm" @click="clearAndClose" />
          <mf-button color="primary" label="Aplicar" size="sm" @click="saveAndClose" />
        </template>
      </v-date-picker>
    </div>
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Array, String]
    },
    type: {
      type: String,
      default: 'date',
      validator: value => ['date', 'month', 'range'].includes(value)
    },
    label: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    left: {
      type: Boolean,
      default: false
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    filterStyle: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    }
  },
  data: () => ({
    dates: [],
    singleDate: '',
    menu: false
  }),
  computed: {
    minRangeDate() {
      if (this.dates[0]) return this.dates[0]
      else if (this.min) return this.min
      else return null
    },
    todayDate() {
      const today = DateTime.now().toISODate()
      return this.type === 'range' ? this.dates[0] === today && this.dates[1] === today : this.singleDate === today
    },
    thisWeek() {
      const startOfWeek = DateTime.now()
        .startOf('week')
        .toISODate()
      const endOfWeek = DateTime.now()
        .endOf('week')
        .toISODate()
      return this.type === 'range' ? this.dates[0] === startOfWeek && this.dates[1] === endOfWeek : this.singleDate === startOfWeek
    },
    thisMonth() {
      const startOfMonth = DateTime.now()
        .startOf('month')
        .toISODate()
      const endOfMonth = DateTime.now()
        .endOf('month')
        .toISODate()
      return this.type === 'range' ? this.dates[0] === startOfMonth && this.dates[1] === endOfMonth : this.singleDate === startOfMonth
    },
    lastMonth() {
      const startOfLastMonth = DateTime.now()
        .minus({ months: 1 })
        .startOf('month')
        .toISODate()
      const endOfLastMonth = DateTime.now()
        .minus({ months: 1 })
        .endOf('month')
        .toISODate()
      return this.type === 'range' ? this.dates[0] === startOfLastMonth && this.dates[1] === endOfLastMonth : this.singleDate === startOfLastMonth
    },
    formatedDate() {
      switch (this.type) {
        case 'range':
          if (!this.dates) return ''
          const startDate = DateTime.fromISO(this.dates[0])
          const endDate = DateTime.fromISO(this.dates[1])
          return `${startDate.isValid ? startDate.toFormat('dd/MM/yy') : ''} - ${endDate.isValid ? endDate.toFormat('dd/MM/yy') : ''}`
        case 'month':
          if (!this.singleDate) return ''
          return DateTime.fromISO(this.singleDate)
            .setLocale('pt-br')
            .toFormat('MMMM yyyy')
        case 'date':
        default:
          if (!this.singleDate) return ''
          return DateTime.fromISO(this.singleDate).toFormat('dd/MM/yy')
      }
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.type === 'range' ? (this.dates = newValue) : (this.singleDate = newValue)
    }
  },
  beforeMount() {
    if (this.value) this.type === 'range' ? (this.dates = this.value) : (this.singleDate = this.value)
  },
  methods: {
    setToday() {
      const today = DateTime.now().toISODate()
      this.type === 'range' ? (this.dates = [today, today]) : (this.singleDate = today)
    },
    setThisWeek() {
      const startOfWeek = DateTime.now()
        .startOf('week')
        .toISODate()
      const endOfWeek = DateTime.now()
        .endOf('week')
        .toISODate()
      this.type === 'range' ? (this.dates = [startOfWeek, endOfWeek]) : (this.singleDate = startOfWeek)
    },
    setThisMonth() {
      const startOfMonth = DateTime.now()
        .startOf('month')
        .toISODate()
      const endOfMonth = DateTime.now()
        .endOf('month')
        .toISODate()
      this.type === 'range' ? (this.dates = [startOfMonth, endOfMonth]) : (this.singleDate = startOfMonth)
    },
    setLastMonth() {
      const startOfMonth = DateTime.now()
        .minus({ months: 1 })
        .startOf('month')
        .toISODate()
      const endOfMonth = DateTime.now()
        .minus({ months: 1 })
        .endOf('month')
        .toISODate()
      this.type === 'range' ? (this.dates = [startOfMonth, endOfMonth]) : (this.singleDate = startOfMonth)
    },
    clearDates() {
      this.type === 'range' ? (this.dates = []) : (this.singleDate = '')
    },
    clearAndClose() {
      this.clearDates()
      this.saveAndClose()
    },
    saveAndClose() {
      const value = this.type === 'range' ? this.dates : this.singleDate
      this.menu = false
      this.$refs.menu.save(value)
      this.$emit('change', value)
    },
    toggleDateSelection() {
      this.menu = !this.menu
    }
  }
}
</script>

<style lang="scss" scoped>
.date-select-text-field {
  border-radius: 8px;
  margin-top: -2px;
  min-width: 128px;
  max-width: 220px;

  ::v-deep input {
    color: #334a58;
    font-size: 14px;
  }

  ::v-deep .v-label {
    color: #334a58;
    font-size: 14px;
  }

  ::v-deep fieldset {
    border-color: #334a58;
  }

  ::v-deep .v-icon {
    color: #334a58;
  }
}

.pre-selected-date-btn {
  color: white;
  margin: 4px 0;
  padding: 6px 8px;
  border-radius: 8px;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.103);
  }
}

.active-pre-selected-date {
  background-color: white;
  color: #334a58;

  &:hover {
    background-color: white;
    color: #334a58;
  }
}
</style>
